// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
/* eslint-disable */
import CartMod from '~/packages/spa-ec/src/components/Cart/CartMod.theme-lamportillallt.js';
import CartTable from '~/packages/spa-ec/src/displayComponents/CartTable/CartTable.theme-lamportillallt.js';
import CategoryListing from '~/packages/spa-ec/src/components/CategoryListing/CategoryListing.theme-lamportillallt.js';
import ContentPage from '~/packages/spa-ec/src/routes/ContentPage/ContentPage.theme-lamportillallt.js';
import DesktopMenuSubSection from '~/packages/spa-ec/src/components/Header/HeaderDesktopMenu/DesktopMenuSubSection.theme-lamportillallt.js';
import Footer from '~/packages/spa-ec/src/components/Footer/Footer.theme-lamportillallt.js';
import Login from '~/packages/spa-ec/src/components/Login/Login.theme-lamportillallt.js';
import ModelShelf from '~/packages/spa-ec/src/components/ModelShelf/ModelShelf.theme-lamportillallt.js';
import MyPages from '~/packages/spa-ec/src/components/MyPages/MyPages.theme-lamportillallt.js';
import OrderConfirmation from '~/packages/spa-ec/src/components/OrderConfirmation/OrderConfirmation.theme-lamportillallt.js';
import PageTitle from '~/packages/spa-ec/src/components/StaticPageComponents/PageTitle.theme-lamportillallt.js';
import PasswordRequest from '~/packages/spa-ec/src/components/PasswordRequest/PasswordRequest.theme-lamportillallt.js';
import ProductDetails from '~/packages/spa-ec/src/components/ProductDetails/themes/ProductDetails.theme-lamportillallt.js';
import QuantityCounter from '~/packages/spa-ec/src/components/QuantityCounter/QuantityCounter.theme-lamportillallt.js';
import ShelfSection from '~/packages/spa-ec/src/components/ModelShelf/ShelfSection.theme-lamportillallt.js';
import SiteLogo from '~/packages/spa-ec/src/components/Header/HeaderSiteLogo/SiteLogo.theme-lamportillallt.js';
import USPWrapper from '~/packages/spa-ec/src/displayComponents/Cms/USPWrapper.theme-lamportillallt.js';

const overrides = {
  CartMod,
  CartTable,
  CategoryListing,
  ContentPage,
  DesktopMenuSubSection,
  Footer,
  Login,
  ModelShelf,
  MyPages,
  OrderConfirmation,
  PageTitle,
  PasswordRequest,
  ProductDetails,
  QuantityCounter,
  ShelfSection,
  SiteLogo,
  USPWrapper,
};
export default overrides;

// THIS FILE IS AUTO-GENERATED by gen-theme-imports.js ANY CHANGES WILL BE OVERWRITTEN
