// eslint-disable-next-line no-unused-vars
const themeGen = (theme) => {
  const res = {
    conf: {
      showSideNavigationOnStaticPages: true,
      showTitleOnContentPageDesktop: true,
      showTitleOnContentPageMobile: true,
      showHomepageCategorySection: true,
    },
    linkColor: theme.col.primary.fg,
    linkColorHover: theme.col.secondary.fg,
    sideContentPadding: 'px-4',
  };
  return res;
};

export default themeGen;
